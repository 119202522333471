import React, { useState } from "react";

import { useStaticQuery, graphql } from "gatsby";

// Components
import SecondaryLanding from "../layouts/secondary-landing";
import HeroChevron from "../components/hero/hero-chevron";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import SEO from "../components/seo/seo";
import getHeroImgVariables from "../helpers/getHeroImgVariables";
import BestBanksDefault from "../components/best-banks/best-banks-default";
import Button from "../components/custom-widgets/button";
import NotificationAlert from "../components/notifications/notification-alert";

const Help = () => {
  const title = "Virtual Banking Support";
  const description =
    "WaFd Bank customers can address their financial needs without leaving home or from any location. Our WaFd Bank Virtual Banking Guides offer tailored support in real-time or by scheduled appointment, ensuring you get the help you need.";

  const PAGE_DATA = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(relativePath: { eq: "hero/help/hero-virtual-banking-support-052324-XXL.jpg" }) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/help/hero-virtual-banking-support-052324-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/help/hero-virtual-banking-support-052324-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/help/hero-virtual-banking-support-052324-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/help/hero-virtual-banking-support-052324-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/help/hero-virtual-banking-support-052324-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(relativePath: { eq: "hero/help/hero-virtual-banking-support-052324-XXS.jpg" }) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const [meetingId, setMeetingId] = useState();
  const [showWarning, setShowWarning] = useState("");

  const heroChevronData = {
    id: "help-hero",
    ...getHeroImgVariables(PAGE_DATA),
    altText: "Smiling woman having a video call via laptop computer in the home office.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Virtual Banking Support"
          }
        },
        {
          id: 2,
          subheading: {
            class: "text-white",
            text: "Enjoy WaFd financial services and support from the comfort of your home"
          }
        }
      ]
    }
  };

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/help"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-virtual-banking-support-052324.jpg"
      },
      {
        name: "robots",
        content: "noindex, nofollow"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      active: "true",
      title: "Virtual Banking Support"
    }
  ];

  const handleChange = (event) => {
    setMeetingId(event.target.value);
    setShowWarning(false);

    console.log("meeting id:", meetingId);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (meetingId && meetingId.length > 8 && typeof window !== "undefined") {
      const zoomMeetingUrl = `https://zoom.us/join?confno=${meetingId}`;
      // Redirect the user to the Zoom meeting URL
      window.open(zoomMeetingUrl, "_blank");

      // console.log(meetingId);
    } else {
      setShowWarning(true);
      // console.log(meetingId);
    }
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Virtual Banking Support</h1>
        <h2 className="text-success">Access Personalized Financial Support Virtually, Anytime</h2>
        <h3>
          WaFd Bank customers can address their financial needs without leaving home or from any location. Our WaFd Bank
          Virtual Banking Guides offer tailored support in real-time or by scheduled appointment, ensuring you get the
          help you need.
        </h3>

        <NotificationAlert
          type="primary"
          class="py-3"
          id="investor-info-notification-alert"
          bodyText='Call us at <a href="tel:800-324-9375" id="alert-c3-tel-link">800-324-9375</a> to get a meeting ID before clicking the Join Meeting button'
        />
        <Button
          id="app-zoom-join-btn"
          url="https://app.zoom.us/wc/join"
          showIcon={false}
          text="Join Meeting"
          class="btn-primary no-min-width"
        />
      </section>

      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default Help;
